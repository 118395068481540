import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"

import formatDate from "../utils/formatDate"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Banner from "../components/widgets/banner"
import PageLink from "../components/shared/pageLink"
import Image from "../components/shared/image"
import Reveal from "react-reveal/Reveal"

const BlogPage = ({ data }) => {
  const isFirstRender = useRef(true)
  const listRef = useRef()
  const lastItem = useRef()

  const doc = data.prismic.allBlog_lists.edges.slice(0, 1).pop()
  // if (!doc) return null;

  const list = data.prismic.allBlog_posts.edges
  const pageChunkSize = 8

  const [currentChunk, setCurrentChunk] = useState(pageChunkSize)
  const [pagination, setPagination] = useState(
    list.filter((item, i) => i < pageChunkSize)
  )

  const handleLoadMore = () => {
    const nextChunk = currentChunk + pageChunkSize
    const nextArticles = list.filter(
      (item, i) => i > currentChunk - 1 && i < nextChunk
    )

    lastItem.current = listRef.current.lastChild

    setCurrentChunk(nextChunk)
    setPagination([...pagination, ...nextArticles])
  }

  useEffect(() => {
    if (!isFirstRender.current) {
      // set focus on newest item added
      lastItem.current.nextSibling.querySelector("a").focus()
    }
  }, [pagination])

  useEffect(() => {
    isFirstRender.current = false // toggle flag after first render/mounting
  }, [])

  return (
    <Layout pageTitle={doc.node.page_heading[0].text}>
      <SEO
        title={
          doc.node.meta_title
            ? doc.node.meta_title
            : doc.node.page_heading[0].text
        }
        image={doc.node.meta_image}
        description={doc.node.meta_description}
      />

      <Banner content={doc.node.banner_title} />

      <section className={`grid-container`}>
        <div className={`grid-x grid-margin-y`}>
          <div className={`cell`}>
            <ul
              ref={listRef}
              className={`u-list-reset grid-x grid-margin-y grid-margin-x`}
            >
              {pagination.map((item, index) => (
                <Reveal key={item.node._meta.uid} effect="fadeInUp">
                  <li className={`cell medium-6`}>
                    <article className={`o-card-link`}>
                      <div className={`o-card-link__image`}>
                        <Image
                          sharpType="fluid"
                          sharpImage={
                            item.node.main_imageSharp.childImageSharp.fluid
                          }
                          alt={item.node.main_image.alt}
                          defaultImage={item.node.main_image.url}
                        />
                      </div>
                      <div>
                        <h3>
                          <PageLink
                            className={`o-card-link__title`}
                            item={item.node}
                          >
                            {item.node.title[0].text}
                          </PageLink>
                        </h3>
                        <p className={`u-text-caption`}>
                          {" "}
                          By {item.node.author} |{" "}
                          {formatDate(item.node.date_posted)}
                        </p>
                      </div>
                    </article>
                  </li>
                </Reveal>
              ))}
            </ul>
          </div>
          {list.length > currentChunk && (
            <>
              <div className={`cell`}>
                <hr />
              </div>
              <div className={`cell u-text-center`}>
                <Reveal effect="fadeInUp" fraction={0.05}>
                  <button
                    className={`c-button `}
                    onClick={() => handleLoadMore()}
                  >
                    Load more
                  </button>
                </Reveal>
              </div>
            </>
          )}
        </div>
      </section>

      {doc.node.secondary_title && (
        <Banner content={doc.node.secondary_title} color="red" />
      )}
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery {
    prismic {
      allBlog_posts(sortBy: date_posted_DESC) {
        edges {
          node {
            _linkType
            _meta {
              id
              type
              uid
            }
            title
            author
            main_image
            main_imageSharp {
              childImageSharp {
                fluid(maxHeight: 452, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date_posted
          }
        }
      }
      allBlog_lists {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            page_title
            page_heading
            banner_title
          }
        }
      }
    }
  }
`

export default BlogPage
